import React, { useState } from "react";
import ImageGrid from "../ImageGrid";
import KeywordInput from "../KeywordInput";
import { Button } from "antd";
 
const SEO = () => {
  const [data, setData] = useState('')
 
 
  return (
    <div>
      <ImageGrid setData={setData}/>
      <KeywordInput data={data}/>
      <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
        {/* <Button type="primary" style={{ width: "100%" }}>
          Submit
        </Button> */}
      </div>
    </div>
  );
};
 
export default SEO;