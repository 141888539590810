import React from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { LayoutFilled, ContainerFilled, SyncOutlined } from "@ant-design/icons";
import { brand, routes } from "../../util/constants";
import logo from "../../assests/img/advertisement_new.png";

export default function SiderMenu(props) {
  const location = useLocation();

  return (
    <div
      className={props.collapsed ? "sider-menu" : "sider-menu menu-position"}
    >
      <div className="side-header">
        <div className="brand-name">
          <div className="brand-logo">
            <img className="logo" src={logo} alt="Logo" />
            <span
              className="app-name mt-1"
              style={{ fontSize: "15px" }}
              mode="full"
            >
              {brand.NAME}
            </span>
          </div>
        </div>
      </div>

      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item
          key={routes.ADMIN_DASHBOARD}
          icon={<LayoutFilled />}
          className="menu-item"
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.ADMIN_DASHBOARD}
          >
            Dashboard
          </NavLink>
        </Menu.Item>
        <p className="menu-subhead" mode="full">
          OPERATIONS
        </p>
        <Menu.Item
          key={routes.SEO}
          icon={<ContainerFilled />}
          className="menu-item"
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.SEO}
          >
            SEO
          </NavLink>          
        </Menu.Item>

        <Menu.Item
          key={routes.BLOGS}
          icon={<SyncOutlined />}
          className="menu-item"
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.BLOGS}
          >
            BLOGS
          </NavLink>          
        </Menu.Item>
      </Menu>
    </div>
  );
}
