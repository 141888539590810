import React, { useState, useEffect } from "react";
import { EyeOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import axios from "axios";

const GeneratedBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });

  const getUserName = () => {
    try {
      for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        let value = localStorage.getItem(key);
        if (value && value.includes('"username"')) {
          let userData = JSON.parse(value);
          return userData.username;
        }
      }
    } catch (e) {
      console.error("Error retrieving username:", e);
      return null;
    }
  };


  const getName = () => {
    try {
      for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        let value = localStorage.getItem(key);
        if (value && value.includes('"name"')) {
          let userData = JSON.parse(value);
          return userData.name;
        }
      }
    } catch (e) {
      console.error("Error retrieving username:", e);
      return null;
    }
  };

  const extractTitleFromTemplate = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.querySelector("title")?.textContent || "Untitled";
  };

  const email = getUserName();
  const name = getName();
  console.log("User email:", email);

  useEffect(() => {
    const fetchBlogs = async () => {
      if (!email) {
        setError("User email not found");
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(
          "http://localhost:8000/api/getUserTemplates",
          {
            params: { email: email },
          }
        );

        if (response.data && Array.isArray(response.data)) {
          // Reversing the blogs array to show the most recent first
          setBlogs(response.data.reverse());
        } else {
          setError("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError(error.response?.data?.message || "Failed to fetch blogs");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [email]);

  const handleView = async (blog) => {
    try {
      const response = await axios.get(
        `http://localhost:8000/api/getUserTemplateById/${blog._id}`,
        {
          params: { email: email },
        }
      );
  
      console.log("Full API Response:", response.data);
      const blogContent = response.data.templates?.temp;
      const uniqueId = response.data._id; // Assuming '_id' is returned from the backend
      const userEmail = response.data.email;
      const name = getName(); 
  
      if (!uniqueId) {
        console.error("ID (_id) is missing from the API response. Full response:", response.data);
        setError("Failed to retrieve the blog ID.");
        return;
      }
  
      // Construct the payload for the likes API
      const payload = {
        uniqueId: uniqueId, // Updated to match the expected key
        userEmail: email,
      };
      console.log("Payload to be sent to likes API:", payload);
  
      // Check if the payload is valid
      if (!payload.uniqueId) {
        console.error("Invalid payload, missing 'uniqueId'. Payload:", payload);
        setError("Invalid ID in the payload.");
        return;
      }
  
      // Call the likes API with the payload
      // const likesResponse = await axios.post("http://localhost:8000/api/likes", payload);
      // console.log("Likes API response:", likesResponse.data);
  
      // Open the blog content in a new tab
      const newTab = window.open();
      if (newTab) {
        newTab.userEmail = email;
        newTab.username = name; // Username here 
        newTab.blogId = uniqueId;
        newTab.document.write(blogContent);
        newTab.document.close();
      } else {
        console.error("Failed to open a new tab. Please enable popups.");
      }
    } catch (error) {
      console.error("Error fetching blog content or sending likes API request:", error);
      setError("Failed to fetch blog content or update likes.");
    }
  };
  
  
  
  
  
  

  const handleDelete = async (id) => {
    try {
      await axios.delete("http://localhost:8000/api/deleteUserTemplate", {
        data: { email: email, id: id },
      });
      const updatedBlogs = blogs.filter((blog) => blog._id !== id);
      setBlogs(updatedBlogs);
      setSelectedBlog(null);
    } catch (error) {
      console.error("Error deleting blog:", error);
      setError("Failed to delete blog");
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        Loading blogs...
      </div>
    );
  }

  // Handle table pagination
  const currentItems = blogs.slice((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Ensure pagination is at the bottom
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        maxWidth: "1200px",
        margin: "40px auto",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "20px",
      }}
    >
      {/* Blogs List */}
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            width: selectedBlog ? "60%" : "100%",
            padding: "20px",
            borderRight: selectedBlog ? "1px solid #ddd" : "none",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              marginBottom: "20px",
              color: "#333",
              fontSize: "22px",
            }}
          >
            Blogs
          </h2>
          {blogs.length > 0 ? (
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                backgroundColor: "#fff",
                borderRadius: "8px",
                overflow: "hidden",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#663399", color: "#fff" }}>
                  <th
                    style={{
                      padding: "12px",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    S No.
                  </th>
                  <th
                    style={{
                      padding: "12px",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Blog Name
                  </th>
                  <th
                    style={{
                      padding: "12px",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((blog, index) => (
                  <tr
                    key={blog._id}
                    style={{
                      borderBottom: "1px solid #ddd",
                      backgroundColor:
                        selectedBlog?._id === blog._id ? "#f0f0f0" : "white",
                    }}
                  >
                    <td
                      style={{
                        padding: "12px",
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#555",
                      }}
                    >
                      {index + 1 + (pagination.current - 1) * pagination.pageSize}
                    </td>
                    <td
                      style={{
                        padding: "12px",
                        textAlign: "center",
                        color: "#333",
                        fontSize: "14px", // Reduced size
                        fontWeight: "500",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                      }}
                    >
                      {extractTitleFromTemplate(blog.templates?.temp)}
                    </td>
                    <td style={{ padding: "12px", textAlign: "center" }}>
                      <EyeOutlined
                        onClick={() => handleView(blog)}
                        style={{
                          fontSize: "16px", // Adjusted size
                          color: "#007bff",
                          cursor: "pointer",
                          marginRight: "10px",
                        }}
                        title="View Blog"
                      />
                      <DeleteOutlined
                        onClick={() => handleDelete(blog._id)}
                        style={{
                          fontSize: "16px", // Adjusted size
                          color: "red",
                          cursor: "pointer",
                        }}
                        title="Delete Blog"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p style={{ textAlign: "center", color: "#888" }}>
              No blogs available.
            </p>
          )}
        </div>

        {/* Blog Content View */}
        {selectedBlog && (
          <div
            style={{
              width: "40%",
              padding: "20px",
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CloseOutlined
              onClick={() => setSelectedBlog(null)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "18px",
                color: "#555",
              }}
              title="Close"
            />
            <h2
              style={{
                fontSize: "18px",
                fontWeight: "600",
                marginBottom: "10px",
                color: "#333",
              }}
            >
              {extractTitleFromTemplate(selectedBlog.templates?.temp)}
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: selectedBlog.content }}
              style={{
                maxHeight: "600px",
                overflowY: "auto",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                fontSize: "14px",
                lineHeight: "1.6",
                color: "#555",
              }}
            />
            {/* Display two images */}
            <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
              <img
                src="https://via.placeholder.com/200"
                alt="Blog Image 1"
                style={{ width: "45%", borderRadius: "8px" }}
              />
              <img
                src="https://via.placeholder.com/200"
                alt="Blog Image 2"
                style={{ width: "45%", borderRadius: "8px" }}
              />
            </div>
          </div>
        )}
      </div>

      {/* Custom Pagination */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
          marginRight: "20px",
        }}
      >
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={blogs.length}
          onChange={(page) => setPagination({ ...pagination, current: page })}
          showSizeChanger={false}
          showTotal={(total) => `Total ${total} items`}
        />
      </div>
    </div>
  );
};

export default GeneratedBlogs;
