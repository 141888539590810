import React, { useState } from "react";
import { Row, Col, Modal, Typography } from "antd";
import image1 from "../assests/img/image1.png";
import image2 from "../assests/img/image2.png";
import image3 from "../assests/img/image3.png";
import image4 from "../assests/img/image4.jpeg";
import image5 from "../assests/img/image5.jpeg";


 
const { Title } = Typography;
const images = [image1, image2, image3, image4, image5];
 
const ImageGrid = ({ setData }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
 
  // Function to simulate saving template to backend
  const saveTemplateToBackend = async (templateIndex) => {
    try {
      const response = await fetch("http://localhost:8000/api/generate-keywords", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ templateId: templateIndex }),
      });
 
      if (response.ok) {
        console.log(`Template ${templateIndex} has been saved to backend`);
        setIsSaved(true);
      } else {
        const errorData = await response.json();
        console.error("Failed to save template:", response.status, errorData);
      }
    } catch (error) {
      console.error("Error saving template:", error);
    }
  };
 
  // Function to handle image selection
  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setSelectedIndex(index);
    setData(index + 1); // Pass the template choice (index + 1) to the parent component
 
    console.log(`Template ${index + 1} is selected`);
    // Uncomment to enable save to backend functionality
    // saveTemplateToBackend(index + 1);
  };
 
  return (
    <div style={{ padding: "0", margin: "0" }}>
      <Title level={3} style={{ textAlign: "center", color: "#555", marginBottom: "20px" }}>
        Please Select a Template
      </Title>
      <Row gutter={[24, 24]} justify="center">
        {images.map((image, idx) => (
          <Col xs={24} sm={12} md={8} key={idx}>
            <div
              style={{
                width: "100%",
                height: "180px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 8,
                overflow: "hidden",
                border: selectedIndex === idx ? "3px solid #1890ff" : "none",
              }}
            >
              <img
                src={image}
                alt={`Template ${idx + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                  transition: "transform 0.3s",
                }}
                onClick={() => handleImageClick(image, idx)}
                onDoubleClick={() => setSelectedImage(image)}
                onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
              />
            </div>
          </Col>
        ))}
      </Row>
 
      {isSaved && <p style={{ textAlign: "center", color: "green" }}>Template has been saved!</p>}
 
      <Modal
        visible={!!selectedImage}
        onCancel={() => setSelectedImage(null)}
        footer={null}
        centered
        style={{ padding: 0, borderRadius: 8, top: 20 }}
        destroyOnClose
      >
        <img src={selectedImage} alt="Selected" style={{ width: "100%", borderRadius: 8 }} />
      </Modal>
    </div>
  );
};
 
export default ImageGrid;
 
 