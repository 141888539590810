export const routes = {
  HOME: "/",
  ADMIN_DASHBOARD: "/dashboard",
  SEO: "/seo",
  BLOGS: "/GeneratedBlogs",

};
export const brand = {
  NAME: "SEO BOT",
};
