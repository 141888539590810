import React, { useState, useEffect } from "react";
import { Input, Button, Upload, Progress, Alert } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import ReactDOM from "react-dom/client";
 
const KeywordInput = ({ data }) => {
  const [keyword, setKeyword] = useState("");
  const [prompt, setPrompt] = useState("");
  const [uploadedImage, setUploadedImage] = useState([]);
  const [imagePaths, setImagePaths] = useState([]); // Changed to an array
  const [generatedUrls, setGeneratedUrls] = useState({});
  const [generatedHtml, setGeneratedHtml] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressStatus, setProgressStatus] = useState(null);
 
  // Automatically generate prompt when keyword changes
  useEffect(() => {
    if (keyword) {
      setPrompt(`Create a blog on ${keyword}`);
    }
  }, [keyword]);
 
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };
 
  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };
 
  // Handle file change
  const handleFileChange = (event) => {
    const fileList = event.target.files || []; // Get the file list
    if (fileList.length > 0) {
      const newPaths = Array.from(fileList).map((file) => file.name); // Use file.name for the paths
 
      // Append the new file names to the existing array
      setImagePaths((prevPaths) => [...prevPaths, ...newPaths]);
      console.log("Updated image paths:", [...imagePaths, ...newPaths]);
    }
  };
 
  // Handle delete functionality
  const handleDeleteImage = (index) => {
    const updatedPaths = imagePaths.filter((_, i) => i !== index); // Remove the selected image by index
    setImagePaths(updatedPaths);
    console.log(`Image at index ${index} removed.`);
  };
 
  // Function to simulate slower progress
  const simulateProgress = () => {
    let currentProgress = 0;
    const interval = setInterval(() => {
      currentProgress += 5; // Slower increment
      setProgress(currentProgress);
 
      if (currentProgress >= 90) {
        clearInterval(interval);
      }
    }, 800); // Slower interval
 
    return interval;
  };
 
  // Function to call the generate-keywords API
  const generateKeywords = async () => {
    // Reset previous states
    setLoading(true);
    setProgress(0);
    setProgressStatus("active");
 
    // Start progress simulation
    const progressInterval = simulateProgress();
 
    try {
      const response = await fetch(
        "http://localhost:8000/api/generate-keywords",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            seedKeyword: keyword.trim(),
            htmlTemplate: data,
            prompt: prompt,
          }),
        }
      );
 
      if (!response.ok) {
        throw new Error("Failed to generate keywords");
      }
 
      const result = await response.json();
      console.log("Keywords API response:", result);
 
      if (result.topKeywords && Array.isArray(result.topKeywords)) {
        console.log("Generated Keywords:");
        result.topKeywords.forEach((keywordObj) => {
          console.log(
            `Keyword: ${keywordObj.keyword}, Avg Monthly Searches: ${keywordObj.avgMonthlySearches}, Competition: ${keywordObj.competition}`
          );
        });
 
        // Proceed to generate link after keywords are generated
        await generateLink(result.topKeywords);
      } else {
        console.error("No keywords generated:", result);
        throw new Error("No keywords generated");
      }
    } catch (error) {
      window.alert("Server Error, Please try again after sometime!");
      console.error("Error generating keywords:", error);
      clearInterval(progressInterval);
      setProgress(0);
      setProgressStatus("exception");
      setLoading(false);
    }
  };
 
  // Function to call the generate-link API
  const generateLink = async (topKeywords) => {
    try {
      const response = await fetch("http://localhost:8000/api/generate-link", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ topKeywords }),
      });
 
      if (!response.ok) {
        throw new Error("Failed to generate links");
      }
 
      const result = await response.json();
      console.log("Links API response:", result);
      setGeneratedUrls(result);
 
      // Automatically generate the blog after links are generated
      if (response.ok) {
        const allUrls = Object.values(result).flat();
        await generateBlog(allUrls);
      }
    } catch (error) {
      window.alert("Server Error, Please try again after sometime!");
      console.error("Error generating link:", error);
      setProgressStatus("exception");
      setLoading(false);
    }
  };
 
  const getUserName = () => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let value = localStorage.getItem(key);
 
      if (value && value.includes('"username"')) {
        try {
          let userData = JSON.parse(value);
          if (userData.username) {
            console.log(`User 's email: ${userData.username}`);
            return userData.username;
          }
        } catch (e) {
          console.log("Error parsing JSON:", e);
        }
      }
    }
    return null;
  };


  const getName = () => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      let value = localStorage.getItem(key);
 
      if (value && value.includes('"name"')) {
        try {
          let userData = JSON.parse(value);
          if (userData.name) {
            console.log(`User 's Name: ${userData.name}`);
            return userData.name;
          }
        } catch (e) {
          console.log("Error parsing JSON:", e);
        }
      }
    }
    return null;
  };
 
  const generateBlog = async (allUrls) => {
    if (!allUrls || !allUrls.length) {
      console.error("No URLs provided.");
      setProgressStatus("exception");
      return;
    }
 
    const requestData = {
      urls: allUrls,
      prompt: prompt,
      template_choice: data,
      image_path: imagePaths,
    };
 
    console.log("generate-blog API request", requestData);
 
    try {
      // First API call to generate blog content
      const response = await axios.post(
        "http://localhost:8080/generate-blog",
        requestData
      );
 
      if (response.status === 200 && response.data) {
        // Set progress to 100%
        setProgress(100);
        setProgressStatus("success");
 
        const newTab = window.open();
        const generateBlog = response.data;
        const userName = getUserName();  // User email fetched here 
        const name = getName(); // User name fetched here
 
        try {
          // Second API call to save user data
          const saveUserResponse = await fetch(
            `http://localhost:8000/api/saveUserData`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                email: userName,
                templates: {
                  temp: generateBlog,
                },
              }),
            }
          );
 
          if (!saveUserResponse.ok) {
            throw new Error(`HTTP error! status: ${saveUserResponse.status}`);
          }
 
          console.log('Raw response:', saveUserResponse);
          console.log('Response status:', saveUserResponse.status);
         
          const responseData = await saveUserResponse.json();
          console.log('Parsed response data:', responseData);
         
          if (responseData.id) {
            console.log('Blog saved with ID:', responseData.id);
           
            try {
              // Third API call to initialize likes
              const likesResponse = await fetch(
                'http://localhost:8000/api/likes',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    uniqueId: responseData.id
                  })
                }
              );
 
              if (!likesResponse.ok) {
                throw new Error(`HTTP error! status: ${likesResponse.status}`);
              }
 
              const likesData = await likesResponse.json();
              console.log('Likes API response:', likesData);
 
              // Only open the new tab after all API calls are successful
              if (newTab) {
                newTab.blogId = responseData.id;
                newTab.username = name; // Username here
                newTab.userEmail = userName; 
                console.log(name)
         
                newTab.document.write(generateBlog);
                newTab.document.close();
                console.log("Blog generated and opened in a new tab.");
              } else {
                console.error("Failed to open new tab");
                throw new Error("Failed to open new tab");
              }
 
            } catch (likesError) {
              console.error('Error calling likes API:', likesError);
              // Even if likes API fails, we might still want to show the blog
              if (newTab) {
                newTab.blogId = responseData.id;
                newTab.document.write(generateBlog);
                newTab.document.close();
                console.log("Blog displayed despite likes API error");
              }
            }
          } else {
            console.error('No ID in response');
            throw new Error("No ID received from save user data API");
          }
 
          console.log("Blog Generated by ", userName);
          console.log("Blog Generated:", generateBlog);
 
          // Remove loading state but keep progress bar
          setLoading(false);
 
        } catch (saveError) {
          console.error("Error saving user data:", saveError);
          setProgressStatus("exception");
          if (newTab) {
            newTab.close();
          }
          window.alert("Error saving blog data. Please try again.");
        }
 
      } else {
        throw new Error("No HTML content returned or unsuccessful response.");
      }
 
    } catch (error) {
      window.alert("Server Error, Please try again after sometime!");
      console.error("Error generating blog:", error);
      setProgressStatus("exception");
      setProgress(0);
      setLoading(false);
    }
  };
 
 
  const submitKeyword = () => {
    generateKeywords();
  };
 
  return (
    <div style={{ marginTop: "40px" }}>
      {/* Keyword Input */}
      <Input
        placeholder="Enter keyword"
        value={keyword}
        onChange={handleKeywordChange}
        style={{ marginBottom: "10px" }}
        disabled={loading}
      />
 
      {/* Submit and Upload Buttons or Progress Bar */}
      <div style={{ display: "flex", gap: "5px" }}>
        {!loading ? (
          <>
            <Button
              type="primary"
              onClick={submitKeyword}
              style={{
                width: "100%",
                backgroundColor: "#663399",
                borderColor: "#663399",
                color: "white", // Added white text color
              }}
              disabled={!keyword}
            >
              <strong>Submit</strong>
            </Button>
 
            {/* Upload Button */}
            <Upload
              multiple
              showUploadList={false}
              beforeUpload={(file, fileList) => {
                handleFileChange({ target: { files: fileList } });
                return false; // Prevent actual upload
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Images</Button>
            </Upload>
          </>
        ) : (
          <Progress
            percent={progress}
            status={progressStatus}
            style={{ width: "100%", marginTop: "10px" }}
          />
        )}
      </div>
 
      <div>
        {imagePaths.length > 0 && (
          <div>
            <h3>Uploaded Images</h3>
            <ul>
              {imagePaths.map((path, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{path}</span>
                  <Button
                    icon={<DeleteOutlined style={{ color: "red" }} />}
                    onClick={() => handleDeleteImage(index)}
                    style={{
                      marginLeft: "10px",
                      padding: 0, // Remove padding to make the icon fit better
                      border: "none", // Remove button border
                      background: "transparent", // Make the background transparent
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
 
export default KeywordInput;
 
 
 
 